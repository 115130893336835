import { ISGWTConnectConfiguration } from '@sgwt/connect-core';

export interface AppConfig {
  sgwt_env: 'homologation' | 'production';
  sgconnect: ISGWTConnectConfiguration;
  accessRequestUrl: string;
  helpCenter: {
    contact: string;
    subject: string;
  };
  apiConfig: {
    url: string;
  };
}

export function getConfig(): AppConfig {
  return window.argosConfiguration;
}
