import { Footer } from '@/components/layout/Footer';
import { Header } from '@/components/layout/Header';
import { ROUTES } from '@/components/routes';
import { useRum } from '@/hooks/core/useRum';
import { IntlProvider } from '@/providers/IntlProvider';
import { PermissionProvider } from '@/providers/PermissionProvider';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { ErrorBoundary } from './common/ErrorBoundary';
import { Loading } from './common/Loading';

const Home = lazy(() => import('./home/Home'));

const siteId = import.meta.env.VITE_PIWIK_SITE_ID;

export function App() {
  useRum();
  return (
    <ErrorBoundary>
      <PermissionProvider>
        <IntlProvider>
          <div className="app d-flex flex-column h-100 justify-content-between">
            <ToastContainer autoClose={5000} limit={1} />
            <BrowserRouter>
              <Suspense fallback={<Loading />}>
                <Header />
                <main className="panel-height">
                  <Routes>
                    <Route path={ROUTES.HOME} element={<Home />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes>
                </main>
                <Footer />
              </Suspense>
            </BrowserRouter>
            <sgwt-web-analytics site-id={siteId} />
          </div>
        </IntlProvider>
      </PermissionProvider>
    </ErrorBoundary>
  );
}
