export const BUS_TOPIC_GLOBALLANGUAGE = 'global.language';
export const BUS_TOPIC_SGCONNECT_ACCESSTOKEN = 'sg-connect.access-token';
export const BUS_TOPIC_SGCONNECT_USERCONNECTION = 'sg-connect.user-connection';
export const BUS_TOPIC_SGCONNECT_USERINFO = 'sg-connect.user-info';
export const BUS_TOPIC_SGCONNECT_GRANTEDSCOPE = 'sg-connect.granted-scope';

type BusEventCallback<T> = (payload: T | undefined) => void;
type SubscriptionHandle = any;
export const userPermissionNames = [
  'CREDIT_RISK_EVENTS_BASIC',
  'CREDIT_RISK_EVENTS_MARK',
  'CREDIT_RISK_EVENTS_RISQ',
] as const;
export type UserPermissionNameType = typeof userPermissionNames[number];

export interface UserPermission {
  name: UserPermissionNameType;
  constraints: any[];
}

interface UserAuthorization {
  resource: string;
  permissions: UserPermission[];
  resource_id: string;
}

interface UserInfo {
  sub: string;
  zoneinfo: string;
  postal_country: string;
  mail: string;
  igg: string;
  last_name: string;
  login_ad: string;
  company_bdr_name: string;
  given_name: string;
  locale: string;
  contact_id: string;
  sgconnect_id: string;
  user_authorization: UserAuthorization[];
  rc_local_sigle: string;
  sesame_id: string;
  user_bdr_id: string;
  company_bdr_level: string;
  name: string;
  is_sg_group_user: string;
  family_name: string;
  first_name: string;
  company_bdr_id: string;
  preferred_language: string;
  origin_network: string;
  auth_level: string;
}

export interface SgWidgetsBus {
  dangerouslyGetCurrentValue<T>(topicName: string): T | undefined | null;

  subscribe<T>(topicName: string, cb: BusEventCallback<T>): SubscriptionHandle;

  unsubscribe(handle: SubscriptionHandle): void;
}

export function getWidgetBus(): SgWidgetsBus | null {
  const widgetConfiguration = (window as any).SGWTWidgetConfiguration;
  return widgetConfiguration && widgetConfiguration.bus
    ? widgetConfiguration.bus
    : null;
}

export function getWidget<T extends HTMLElement>(tagName: string) {
  return document.querySelector(tagName) as T;
}

export function getAuthorizationHeader(): string | undefined | null {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<string>(
      BUS_TOPIC_SGCONNECT_ACCESSTOKEN,
    );
  }
  return undefined;
}

interface UserConnection {
  connected: boolean;
  claims: Record<string, string>;
  mail: string;
}

export function getUserConnection(): UserConnection | undefined | null {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<UserConnection>(
      BUS_TOPIC_SGCONNECT_USERCONNECTION,
    );
  }
  return undefined;
}

export function getUserPermissions(): Promise<UserPermission[] | undefined> {
  let intervalId: NodeJS.Timer | null = null;
  let retries = 0;
  let MAX_RETRIES = 10;
  return new Promise<UserPermission[] | undefined>(resolve => {
    intervalId = setInterval(() => {
      const info = getUserInfo();
      if (info) {
        const permissions = info.user_authorization.flatMap(
          auth => auth.permissions,
        );
        if (Array.isArray(permissions)) {
          resolve(permissions);
        }
      }
      if (retries >= MAX_RETRIES) {
        resolve(undefined);
      }
      retries++;
    }, 500);
  }).then((result: UserPermission[] | undefined) => {
    if (intervalId) clearInterval(intervalId);
    return result;
  });
}

function getUserInfo(): UserInfo | undefined | null {
  const bus = getWidgetBus();
  if (bus) {
    return bus.dangerouslyGetCurrentValue<UserInfo>(
      BUS_TOPIC_SGCONNECT_USERINFO,
    );
  }
  return undefined;
}

export function getUser(): Promise<UserInfo | undefined | null> {
  let intervalId: NodeJS.Timer | null = null;
  let retries = 0;
  let MAX_RETRIES = 10;
  return new Promise<UserInfo | undefined | null>(resolve => {
    intervalId = setInterval(() => {
      const info = getUserInfo();
      if (info) {
        resolve(info);
      }
      if (retries >= MAX_RETRIES) {
        resolve(undefined);
      }
      retries++;
    }, 500);
  }).then((result: UserInfo | undefined | null) => {
    if (intervalId) clearInterval(intervalId);
    return result;
  });
}