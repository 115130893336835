import { useIntl } from 'react-intl';

export function AccountCenter(): JSX.Element {
  const intl = useIntl();
  return (
    <sgwt-account-center
      available-languages="en"
      authentication="sg-connect-v2"
      mode="sg-markets"
      language={intl.locale}
    />
  );
}
