import { AccountCenter } from '@/components/sgwt/AccountCenter';
import { useCallback, useState } from 'react';
import { getTheme, setTheme, Theme } from '@/utils/theme';
import { Switcher } from '@/components/common/Switcher';
import Logo from './Logo';
import { logger } from '@/log/matomo.logger';
import { useIntl } from 'react-intl';

export function Header(): JSX.Element {
  const intl = useIntl();
  const [currentTheme, setCurrentTheme] = useState(getTheme);

  const changeTheme = useCallback((checked: boolean) => {
    const theme: Theme = checked ? 'DARK' : 'LIGHT';
    logger.trackChangeTheme(theme);
    setTheme(theme);
    setCurrentTheme(theme);
  }, []);

  return (
    <nav className="navbar border-bottom border-top">
      <div className="navbar-title">
        <a href="/" className="navbar-title-link">
          <div className="navbar-logo">
            <Logo />
          </div>
          <div className="navbar-title-divider"></div>
          <div className="navbar-service-name">
            Credit risk
            <br />
            events
          </div>
        </a>
      </div>
      <div className="d-flex align-items-center">
        <Switcher
          id="dark"
          label={intl.formatMessage({ defaultMessage: 'Dark' })}
          checked={currentTheme === 'DARK'}
          onChange={changeTheme}
        />
        <AccountCenter />
      </div>
    </nav>
  );
}
