import { userTypeAtom } from '@/atoms/permissions.atom';
import { Loading } from '@/components/common/Loading';
import { redirectToRequestAccessPage } from '@/utils/url';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

interface PermissionProviderProps {
  children: React.ReactNode;
}

function isDevEnv(): boolean {
  const env = import.meta.env.VITE_APP_ENV;
  return env === 'dev';
}

export const PermissionProvider: React.FC<PermissionProviderProps> = ({
  children,
}) => {
  const [display, setDisplay] = useState(false);
  const userType = useAtomValue(userTypeAtom);

  useEffect(() => {
    (async () => {
      if (isDevEnv() || userType !== 'UNAUTHORIZED') {
        setDisplay(true);
      } else {
        redirectToRequestAccessPage();
      }
    })();
  }, [userType]);

  return <>{display ? children : <Loading />}</>;
};
