import { init } from '@elastic/apm-rum';
import { useEffect } from 'react';

export function useRum(): void {
  const activate = import.meta.env.VITE_APP_RUM_APM_ACTIVATE === 'true';
  useEffect(() => {
    if (!activate) {
      return;
    }
    const serverUrl = import.meta.env.VITE_APP_RUM_APM;
    const env = import.meta.env.VITE_APP_ENV;
    init({
      serviceName: `argos-web-${env}`,
      serverUrl,
    });
  }, [activate]);
}
