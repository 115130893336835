import { ChangeEvent, FC, useCallback } from 'react';

interface SwitcherProps {
  label: string;
  id: string;
  onChange?: (value: boolean) => void;
  checked?: boolean;
}

export const Switcher: FC<SwitcherProps> = ({
  label,
  id,
  onChange,
  checked,
}: SwitcherProps) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.currentTarget.checked);
    },
    [onChange],
  );

  return (
    <div className="form-check form-switch">
      <input
        onChange={handleChange}
        checked={checked}
        type="checkbox"
        className="form-check-input"
        id={id}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};
