import { getConfig } from '@/config/config';
import { useIntl } from 'react-intl';

export function HelpCenter(): JSX.Element {
  const intl = useIntl();
  return (
    <aside>
      <sgwt-help-center
        application-id={import.meta.env.VITE_APP_ID as string}
        default-send-to={getConfig().helpCenter.contact}
        mail-subject={getConfig().helpCenter.subject}
        sg-connect-support="sg-connect-v2"
        language={intl.locale}
      />
    </aside>
  );
}
