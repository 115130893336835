import { Fragment, useEffect, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { BUS_TOPIC_GLOBALLANGUAGE, getWidgetBus } from '@/widgets/widgets';
import en from '@/locale/en.json';

type SupportedLocale = 'en';
export const locales: Record<SupportedLocale, Record<string, string>> = {
  en,
};

export const IntlProvider = ({ children }: { children: JSX.Element }) => {
  const [locale, setLocale] = useState<SupportedLocale>('en');

  const languageCallback = (lang: string | undefined) => {
    if (lang) {
      const locale = lang.toLowerCase() as SupportedLocale;
      if (Object.keys(locale)) {
        setLocale(locale);
        const html = document.querySelector('html');
        if (html) {
          html.lang = lang;
        }
      }
    }
  };

  // Use the SGWT Widgets bus to be aware of the modification of the language,
  // which is triggered by the <sgwt-account-center> widget.
  useEffect(() => {
    const bus = getWidgetBus();
    let languageSubscription: any = undefined;
    if (bus) {
      languageSubscription = bus.subscribe<string>(
        BUS_TOPIC_GLOBALLANGUAGE,
        languageCallback,
      );
    }
    return () => {
      if (bus && languageSubscription) {
        bus.unsubscribe(languageSubscription);
      }
    };
  }, []);

  return (
    <ReactIntlProvider
      locale={locale}
      defaultLocale="en"
      messages={locales[locale]}
      textComponent={Fragment}
    >
      {children}
    </ReactIntlProvider>
  );
};
