import {
  getUserPermissions,
  UserPermission,
  UserPermissionNameType,
} from '@/widgets/widgets';
import { atom } from 'jotai';
import { any } from 'lodash/fp';

export type UserType = 'MARK' | 'RISQ' | 'BASIC' | 'UNAUTHORIZED';

export const userTypeAtom = atom(async () => {
  const permissions = await getUserPermissions();
  return permissions?.length ? getUserType(permissions) : 'UNAUTHORIZED';
});

type UserPermissionName = {
  permission: UserPermissionNameType;
  type: UserType;
};

export const getUserType = (userPermissions: UserPermission[]): UserType => {
  const permissionCheck = userHasPermission(userPermissions);

  const permissions: UserPermissionName[] = [
    { permission: 'CREDIT_RISK_EVENTS_MARK', type: 'MARK' },
    { permission: 'CREDIT_RISK_EVENTS_RISQ', type: 'RISQ' },
    { permission: 'CREDIT_RISK_EVENTS_BASIC', type: 'BASIC' },
  ];

  for (const { permission, type } of permissions) {
    if (permissionCheck(permission)) {
      return type;
    }
  }

  return 'UNAUTHORIZED';
};

const userHasPermission =
  (permissions: UserPermission[]) =>
  (permission: string): boolean =>
    any<UserPermission>(({ name }) => name === permission)(permissions);
