export const themeKey = 'Theme';
export type Theme = 'DARK' | 'LIGHT';

const sgbsVersion = import.meta.env.VITE_SGBS_VERSION;
const cdnUrl = `https://sgwt-cdn-sgbs.sgmarkets.com/sg-bootstrap/${sgbsVersion}`;

function addScript(
  url: string,
  integrity = '',
  async = false,
  type = 'text/javascript',
): HTMLScriptElement | undefined {
  if (!document.getElementById(url)) {
    const script = document.createElement('script');
    script.type = type;
    script.src = url;
    script.id = url;
    script.async = async;
    if (integrity) {
      script.crossOrigin = 'anonymous';
      script.integrity = integrity;
    }
    const head = document.getElementsByTagName('head')[0];
    head.insertBefore(script, head.firstChild);
    return script;
  }
  return undefined;
}

function addBootstrapJS(): void {
  const bundleUrl =
    'https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js';
  addScript(bundleUrl);
}

function variableSgbsUrls(theme: string | null) {
  const variation = theme === 'DARK' ? 'dark' : 'standard';
  return {
    sgbs: `${cdnUrl}/core/css/sg-bootstrap-${variation}.min.css`,
  };
}

export function initSgBootstrap(theme?: Theme) {
  addBootstrapJS();
  updateThemeUrls(theme ?? getTheme());
  appendUniqueLinkToDocument('icons', `${cdnUrl}/icons/index.min.css`);
}

function updateThemeUrls(theme: Theme) {
  Object.entries(variableSgbsUrls(theme)).forEach(([id, url]) => {
    appendUniqueLinkToDocument(id, url);
  });
}

export function setTheme(theme: Theme) {
  updateThemeUrls(theme);
  saveToLocalStorage(theme);
}

export function getTheme(): Theme {
  const savedTheme = getFromLocalStorage();
  if (savedTheme != null) {
    return savedTheme?.toUpperCase() === 'DARK' ? 'DARK' : 'LIGHT';
  }
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  return prefersDark ? 'DARK' : 'LIGHT';
}

function saveToLocalStorage(theme: Theme) {
  if (typeof localStorage === 'undefined') {
    return;
  }
  try {
    localStorage.setItem(themeKey, theme);
  } catch {
    console.error('Unable to store theme, localStorage might be full');
  }
}

function getFromLocalStorage(): string | null {
  if (typeof localStorage === 'undefined') {
    return null;
  }
  return localStorage.getItem(themeKey);
}

function appendUniqueLinkToDocument(id: string, url: string) {
  const linkElement = document.querySelector<HTMLLinkElement>(
    `link[id="${id}"]`,
  );
  const newLinkElement = document.createElement('link');
  newLinkElement.rel = 'stylesheet';
  newLinkElement.id = id;
  newLinkElement.href = url;
  if (linkElement !== null) {
    newLinkElement.onload = () => {
      document.head.removeChild(linkElement);
    };
  }
  const head = document.getElementsByTagName('head')[0];
  head.insertBefore(newLinkElement, head.childNodes[1]);
}
