import { logger } from '@/log/matomo.logger';
import React, { ReactNode } from 'react';

interface IErrorBoundaryState {
  error: Error | null;
}

interface IErrorBoundaryProps {
  children: ReactNode;
}

export class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  public state: IErrorBoundaryState = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public componentDidCatch(
    { message, stack }: Error,
    errorInfo: React.ErrorInfo,
  ) {
    logger.trackRuntimeError(message);
    console.error(
      `ErrorBoundary error[${message}] stack[${stack}] errorInfo[${errorInfo}]`,
    );
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <div style={{ height: '100vh' }}>
          <sg-error-page
            code={500}
            action-button-label="Back to home"
            action-button-link="/"
            contact-us="help-center"
            fullscreen={true}
            description-label="We're unable to complete your request at the moment."
            title-label="My Credit Risk Events"
          />
        </div>
      );
    }

    return children;
  }
}
