export function MiniFooter(): JSX.Element {
  const accessbilityId = 'bd8b7817-6d11-4c84-99d6-e6a3f7083845';
  return (
    <sgwt-mini-footer
      type="compact"
      design="light"
      mode="sg-markets"
      contact-us-by-help-center="sgwt-help-center"
      accessibility-uid="bd8b7817-6d11-4c84-99d6-e6a3f7083845"
      accessibility-link={`https://shared.sgmarkets.com/accessibility/statement-en.html?uid=${accessbilityId}`}
    />
  );
}
