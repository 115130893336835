import React from 'react';

const Logo: React.FC = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="0" width="100%" height="50%" fill="#E60028"></rect>
    <rect x="0" y="50%" width="100%" height="50%" fill="#1D1D1B"></rect>
    <rect x="17%" y="46.5%" width="65%" height="7%" fill="white"></rect>
  </svg>
);

export default Logo;
